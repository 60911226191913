import { useMemo } from 'react'
import { Trade, TradeType } from 'titano-sdk3'
import { Button, Text, ErrorIcon, WarningIcon, ArrowDownIcon } from '@pancakeswap/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import { computeTradePriceBreakdown, warningSeverity, computeSlippageAdjustedAmounts } from 'utils/exchange'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from 'utils/truncateHash'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'
import styled, { useTheme } from 'styled-components'

const ShowAcceptChangeRowBetween = styled(RowBetween)`
  flex-direction: column;
  gap: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

const ShowAcceptChangeButton = styled(Button)`
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: fit-content;
  }
`

const StyledSoftText = styled(Text)`
  color: ${({ theme }) => theme.colors.softTextColor};
`

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  )
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <AutoColumn gap="md">
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <TruncatedText fontSize="20px">{trade.inputAmount.toSignificant(6)}</TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <CurrencyLogo currency={trade.inputAmount.currency} size="32px" />
          <Text fontSize="20px" ml="10px" bold>
            {trade.inputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowFixed style={{ width: '100%' }}>
        <svg
          style={{ margin: '0 auto' }}
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="30" height="30" rx="15" fill={theme.isDark ? '#23304F' : ''} />
          <path
            d="M9.29177 15.715L14.2918 20.715C14.3869 20.806 14.499 20.8774 14.6218 20.925C14.8652 21.025 15.1383 21.025 15.3818 20.925C15.5045 20.8774 15.6167 20.806 15.7118 20.715L20.7118 15.715C20.805 15.6217 20.879 15.5111 20.9294 15.3892C20.9799 15.2674 21.0059 15.1368 21.0059 15.005C21.0059 14.7387 20.9001 14.4833 20.7118 14.295C20.5235 14.1067 20.2681 14.0009 20.0018 14.0009C19.7355 14.0009 19.4801 14.1067 19.2918 14.295L16.0018 17.595L16.0018 10.005C16.0018 9.73977 15.8964 9.48542 15.7089 9.29788C15.5213 9.11034 15.267 9.00499 15.0018 9.00499C14.7366 9.00499 14.4822 9.11034 14.2947 9.29788C14.1071 9.48541 14.0018 9.73977 14.0018 10.005L14.0018 17.595L10.7118 14.295C10.6188 14.2013 10.5082 14.1269 10.3863 14.0761C10.2645 14.0253 10.1338 13.9992 10.0018 13.9992C9.86976 13.9992 9.73905 14.0253 9.61719 14.0761C9.49533 14.1269 9.38473 14.2013 9.29177 14.295C9.19804 14.3879 9.12365 14.4985 9.07288 14.6204C9.02211 14.7423 8.99597 14.873 8.99597 15.005C8.99597 15.137 9.02211 15.2677 9.07288 15.3896C9.12365 15.5114 9.19804 15.622 9.29177 15.715Z"
            fill="#194BFB"
          />
        </svg>
      </RowFixed>
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <TruncatedText fontSize="20px">{trade.outputAmount.toSignificant(6)}</TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <CurrencyLogo currency={trade.outputAmount.currency} size="32px" />
          <Text fontSize="20px" bold ml="10px">
            {trade.outputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0px">
          <ShowAcceptChangeRowBetween>
            <RowFixed>
              <WarningIcon color="secondary" width="20px" mr="10px" />
              <Text style={{ marginLeft: '8px', fontSize: '14px' }}>{t('Price Updated')}</Text>
            </RowFixed>
            <ShowAcceptChangeButton
              style={{ fontSize: '10px' }}
              height="32px"
              padding="0 16px"
              onClick={onAcceptChanges}
            >
              {t('Confirm Swap')}
            </ShowAcceptChangeButton>
          </ShowAcceptChangeRowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '8px 0 0 0px' }}>
        <StyledSoftText fontSize="11px" textAlign="left" style={{ width: '100%' }}>
          {estimatedText}
          <b>
            {amount} {symbol}
          </b>
          {transactionRevertText}
        </StyledSoftText>
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '8px 0 0 0px' }}>
          <Text color="secondary" fontSize="11px">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
