import { Box, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  // background-color: #ffffff; // ${({ theme }) => theme.colors.background};
  flex-shrink: 0;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 12px 32px 35px rgba(15, 20, 30, 0.05);
`

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 380px;
  }
`
