import { Trade, TradeType } from 'titano-sdk3'
import { Text } from '@pancakeswap/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance, useAutoTax, useSellingTax, useBuyingTax } from 'state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from 'utils/exchange'
import { AutoColumn } from 'components/Layout/Column'
import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'

const ElipsisSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 3px;
  overflow: hidden;
`

const StyledRowBetween = styled(RowBetween)`
  max-width: 100%;
`

const Wrapper = styled.div`
  max-width: calc(100vw - 48px);
  margin-top: 16px;
`

export function TradeSummary({
  trade,
  allowedSlippage,
  autoTax,
  maskSlippage,
}: {
  trade: Trade
  allowedSlippage: number
  autoTax: boolean
  maskSlippage: string
}) {
  const { t } = useTranslation()
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { theme } = useTheme()

  return (
    <Wrapper>
      <StyledRowBetween gap="10px">
        <RowFixed>
          <Text fontSize="12px" color="textSubtle">
            {t('AutoTax')}
          </Text>
        </RowFixed>
        <Text style={{ maxWidth: '50%' }} display="flex" fontSize="12px" color={theme.colors.hardTextColor}>
          {autoTax ? t('ON') : t('OFF')}
        </Text>
      </StyledRowBetween>

      <StyledRowBetween gap="10px">
        <RowFixed>
          <Text fontSize="12px" color="textSubtle">
            {t('Slippage')}
          </Text>
        </RowFixed>
        <Text style={{ maxWidth: '50%' }} display="flex" fontSize="12px" color={theme.colors.hardTextColor}>
          {`${maskSlippage} %`}
        </Text>
      </StyledRowBetween>

      <StyledRowBetween gap="10px">
        <RowFixed>
          <Text fontSize="12px" color="textSubtle">
            {isExactIn ? t('Minimum received') : t('Maximum sold')}
          </Text>
        </RowFixed>
        <RowFixed>
          <Text fontSize="12px" color={theme.colors.hardTextColor}>
            {isExactIn
              ? (
                  <>
                    <ElipsisSpan>{slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)}</ElipsisSpan>
                    {trade.outputAmount.currency.symbol}
                  </>
                ) ?? '-'
              : (
                  <>
                    <ElipsisSpan>{slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)}</ElipsisSpan>
                    {trade.inputAmount.currency.symbol}
                  </>
                ) ?? '-'}
          </Text>
        </RowFixed>
      </StyledRowBetween>
      <StyledRowBetween gap="10px">
        <RowFixed>
          <Text fontSize="12px" color="textSubtle">
            {t('Price Impact')}
          </Text>
        </RowFixed>
        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
      </StyledRowBetween>

      <StyledRowBetween gap="10px">
        <RowFixed>
          <Text fontSize="12px" color="textSubtle">
            {t('Liquidity Provider Fee')}
          </Text>
        </RowFixed>
        <Text style={{ maxWidth: '50%' }} display="flex" fontSize="12px" color={theme.colors.hardTextColor}>
          {realizedLPFee ? (
            <>
              <ElipsisSpan>{realizedLPFee.toSignificant(4)}</ElipsisSpan> {` ${trade.inputAmount.currency.symbol}`}
            </>
          ) : (
            '-'
          )}
        </Text>
      </StyledRowBetween>
    </Wrapper>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const { t } = useTranslation()
  const [allowedSlippage] = useUserSlippageTolerance()
  const [sellingTax] = useSellingTax()
  const [buyingTax] = useBuyingTax()
  const [autoTax] = useAutoTax()

  const totalTax = Math.round(allowedSlippage + (autoTax ? +(+buyingTax * 100) + +sellingTax * 100 : 0))

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="0px">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={totalTax} autoTax={autoTax} maskSlippage="" />
          {showRoute && (
            <>
              <RowBetween style={{ padding: '0 16px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Text fontSize="12px" color="textSubtle">
                    {t('Route')}
                  </Text>
                </span>
                <SwapRoute trade={trade} />
              </RowBetween>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
