import styled from 'styled-components'
import {
  ChartIcon,
  Flex,
  Heading,
  HistoryIcon,
  IconButton,
  NotificationDot,
  Text,
  useModal,
  ChartDisableIcon,
} from '@pancakeswap/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useExpertModeManager } from 'state/user/hooks'
import RefreshIcon from 'components/Svg/RefreshIcon'
import useTheme from 'hooks/useTheme'

interface Props {
  title: string
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const CurrencyInputContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 18px 24px 16px;
  width: 100%;
`

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.hardTextColor};
  font-weight: 600;
`

const ColoredIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.textSubtle};
`

const CurrencyInputHeader: React.FC<Props> = ({
  title,
  subtitle,
  setIsChartDisplayed,
  isChartDisplayed,
  hasAmount,
  onRefreshPrice,
}) => {
  const [expertMode] = useExpertModeManager()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)

  const { theme } = useTheme()

  return (
    <CurrencyInputContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-start" width="100%" mr={18}>
          <StyledHeading as="h2">{title}</StyledHeading>
        </Flex>
        <Flex>
          <NotificationDot show={expertMode}>
            <GlobalSettings height={22} mr="0" fill={theme.colors.iconColor} />
          </NotificationDot>
          <IconButton height={22} onClick={onPresentTransactionsModal} variant="text" scale="sm">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 0C7.43639 0.00731219 4.97349 0.998914 3.12 2.77V1C3.12 0.734784 3.01464 0.48043 2.82711 0.292893C2.63957 0.105357 2.38522 0 2.12 0C1.85478 0 1.60043 0.105357 1.41289 0.292893C1.22536 0.48043 1.12 0.734784 1.12 1V5.5C1.12 5.76522 1.22536 6.01957 1.41289 6.20711C1.60043 6.39464 1.85478 6.5 2.12 6.5H6.62C6.88522 6.5 7.13957 6.39464 7.32711 6.20711C7.51464 6.01957 7.62 5.76522 7.62 5.5C7.62 5.23478 7.51464 4.98043 7.32711 4.79289C7.13957 4.60536 6.88522 4.5 6.62 4.5H4.22C5.50578 3.15636 7.21951 2.30265 9.06653 2.08567C10.9136 1.86868 12.7785 2.30198 14.3407 3.31104C15.9028 4.32011 17.0646 5.84191 17.6263 7.61479C18.188 9.38767 18.1145 11.3009 17.4184 13.0254C16.7223 14.7499 15.4472 16.1781 13.8122 17.0643C12.1772 17.9505 10.2845 18.2394 8.45956 17.8813C6.63463 17.5232 4.99147 16.5405 3.81259 15.1022C2.63372 13.6638 1.99279 11.8597 2 10C2 9.73478 1.89464 9.48043 1.70711 9.29289C1.51957 9.10536 1.26522 9 1 9C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10C0 11.9778 0.58649 13.9112 1.6853 15.5557C2.78412 17.2002 4.3459 18.4819 6.17317 19.2388C8.00043 19.9957 10.0111 20.1937 11.9509 19.8079C13.8907 19.422 15.6725 18.4696 17.0711 17.0711C18.4696 15.6725 19.422 13.8907 19.8079 11.9509C20.1937 10.0111 19.9957 8.00043 19.2388 6.17317C18.4819 4.3459 17.2002 2.78412 15.5557 1.6853C13.9112 0.58649 11.9778 0 10 0ZM10 6C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11H12C12.2652 11 12.5196 10.8946 12.7071 10.7071C12.8946 10.5196 13 10.2652 13 10C13 9.73478 12.8946 9.48043 12.7071 9.29289C12.5196 9.10536 12.2652 9 12 9H11V7C11 6.73478 10.8946 6.48043 10.7071 6.29289C10.5196 6.10536 10.2652 6 10 6Z"
                fill={theme.colors.iconColor}
              />
            </svg>
          </IconButton>
          <IconButton height={22} variant="text" scale="sm" onClick={() => onRefreshPrice()}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 10C18.7348 10 18.4804 10.1054 18.2929 10.2929C18.1054 10.4804 18 10.7348 18 11C18.0119 12.873 17.3703 14.6916 16.1857 16.1425C15.001 17.5933 13.3475 18.5857 11.51 18.9486C9.67239 19.3115 7.76578 19.0223 6.11851 18.1308C4.47123 17.2392 3.18641 15.8012 2.48533 14.0643C1.78425 12.3273 1.71079 10.4003 2.27762 8.61508C2.84445 6.82983 4.01607 5.29813 5.5907 4.28378C7.16533 3.26942 9.0444 2.83591 10.9043 3.05791C12.7641 3.27991 14.4884 4.14352 15.78 5.5H13.38C13.1148 5.5 12.8604 5.60536 12.6729 5.79289C12.4854 5.98043 12.38 6.23478 12.38 6.5C12.38 6.76522 12.4854 7.01957 12.6729 7.20711C12.8604 7.39464 13.1148 7.5 13.38 7.5H17.91C18.1752 7.5 18.4296 7.39464 18.6171 7.20711C18.8046 7.01957 18.91 6.76522 18.91 6.5V2C18.91 1.73478 18.8046 1.48043 18.6171 1.29289C18.4296 1.10536 18.1752 1 17.91 1C17.6448 1 17.3904 1.10536 17.2029 1.29289C17.0154 1.48043 16.91 1.73478 16.91 2V3.77C15.2447 2.17806 13.0808 1.20995 10.784 1.02933C8.48729 0.848705 6.19863 1.46664 4.30492 2.77869C2.41121 4.09074 1.02861 6.01641 0.390831 8.23019C-0.246949 10.444 -0.100787 12.8101 0.804611 14.9285C1.71001 17.047 3.31911 18.7878 5.3599 19.8568C7.40069 20.9258 9.748 21.2574 12.005 20.7955C14.2621 20.3336 16.2904 19.1065 17.7471 17.3217C19.2039 15.5369 19.9997 13.3038 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10Z"
                fill={theme.colors.iconColor}
              />
            </svg>
            {/* <RefreshIcon disabled={!hasAmount} color="textSubtle" width="27px" /> */}
          </IconButton>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Text color="textSubtle" fontSize="14px">
          {subtitle}
        </Text>
      </Flex>
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
