import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CurrencyAmount, Token, Trade } from 'titano-sdk3'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/exchange'
import {
  Button,
  Text,
  ArrowDownIcon,
  Box,
  useModal,
  Flex,
  IconButton,
  BottomDrawer,
  ArrowUpDownIcon,
  Skeleton,
  useMatchBreakpointsContext,
  Heading,
  Toggle,
  Input,
} from '@pancakeswap/uikit'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/UnsupportedCurrencyFooter'
import Footer from 'components/Menu/Footer'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
import { EXCHANGE_DOCS_URLS } from 'config/constants'
import { BIG_INT_ZERO } from 'config/constants/exchange'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import shouldShowSwapWarning from 'utils/shouldShowSwapWarning'
import { useWeb3React } from '@web3-react/core'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import useRefreshBlockNumberID from './hooks/useRefreshBlockNumber'
import AddressInputPanel from './components/AddressInputPanel'
import { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Layout/Column'
import ConfirmSwapModal from './components/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { AutoRow, RowBetween } from '../../components/Layout/Row'
import AdvancedSwapDetailsDropdown from './components/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from './components/confirmPriceImpactWithoutFee'
import { ArrowWrapper, SwapCallbackError, Wrapper } from './components/styleds'
import TradePrice from './components/TradePrice'
import ProgressSteps from './components/ProgressSteps'
import { AppBody } from '../../components/App'
import ConnectWalletButton from '../../components/ConnectWalletButton'
import QuestionHelper from '../../components/QuestionHelper'
import { getTokenAddress } from './components/Chart/utils'

import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapState,
  useSingleTokenSwapInfo,
} from '../../state/swap/hooks'
import {
  useExpertModeManager,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
  useExchangeChartManager,
  useAutoTax,
  useSellingTax,
  useBuyingTax,
} from '../../state/user/hooks'
import CircleLoader from '../../components/Loader/CircleLoader'
import Page from '../Page'
import SwapWarningModal from './components/SwapWarningModal'
import PriceChartContainer from './components/Chart/PriceChartContainer'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import CurrencyInputHeader from './components/CurrencyInputHeader'
import ImportTokenWarningModal from '../../components/ImportTokenWarningModal'
import { TradeSummary } from './components/AdvancedSwapDetails'

const SwitchIconButton = styled(IconButton)`
  width: 58px;
  height: 58px;
`

const SwitchHr = styled.hr<{ right?: boolean }>`
  flex-grow: 1;
  height: 0px;
  margin: ${({ right }) => (right ? '24px 0 0 13px' : '24px 13px 0 0')};
  border: 1px solid ${({ theme }) => theme.colors.hrColor};
`

const StyledAppBody = styled(AppBody)`
  max-width: 320px;
  min-width: 356px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 100%;
    min-width: auto;
  }
`

const StyledHr = styled.hr`
  border-color: ${({ theme }) => theme.colors.hrColor};
  border-size: 1px;
  border-style: solid;
  margin: 0 0 20px;
`

export default function Swap() {
  const router = useRouter()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpointsContext()
  const [isChartExpanded, setIsChartExpanded] = useState(false)
  const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference)
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID()
  const [autoTax, setAutoTax] = useAutoTax()
  const [sellingTax, setSellingTax] = useSellingTax()
  const [buyingTax, setBuyingTax] = useBuyingTax()

  useEffect(() => {
    setUserChartPreference(isChartDisplayed)
  }, [isChartDisplayed, setUserChartPreference])

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency],
  )

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !(token.address in defaultTokens)
    })

  const { account } = useWeb3React()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [maskSlippage, setMaskSlippage] = useState('')

  useEffect(() => {
    if (allowedSlippage) {
      setMaskSlippage(`${allowedSlippage / 100}`)
    }
  }, [allowedSlippage])

  useEffect(() => {
    setUserSlippageTolerance(maskSlippage ? Number(maskSlippage) * 100 : 0)
  }, [maskSlippage])

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient,
    inputCurrencyId,
    outputCurrencyId,
  )

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  // modal and loading
  const [{ tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(BIG_INT_ZERO),
  )
  const noRoute = !route
  const totalTax = Math.round(allowedSlippage + (autoTax ? +(+buyingTax * 100) + +sellingTax * 100 : 0))
  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, totalTax)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, totalTax, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee, t)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, swapErrorMessage: undefined, txHash: hash })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [priceImpactWithoutFee, swapCallback, tradeToConfirm, t])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn })
  }, [attemptingTxn, swapErrorMessage, trade, txHash])

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null)
  const [onPresentSwapWarningModal] = useModal(<SwapWarningModal swapCurrency={swapWarningCurrency} />, false)

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency])

  const handleInputSelect = useCallback(
    (currencyInput) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, currencyInput)
      const showSwapWarning = shouldShowSwapWarning(currencyInput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyInput)
      } else {
        setSwapWarningCurrency(null)
      }
    },
    [onCurrencySelection],
  )

  const handleMaxInput = useCallback(
    (e) => {
      e.preventDefault()
      if (maxAmountInput) {
        onUserInput(Field.INPUT, maxAmountInput.toExact())
      }
    },
    [maxAmountInput, onUserInput],
  )

  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput)
      const showSwapWarning = shouldShowSwapWarning(currencyOutput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyOutput)
      } else {
        setSwapWarningCurrency(null)
      }
    },

    [onCurrencySelection],
  )

  const token0Address = getTokenAddress(inputCurrencyId).toLowerCase()
  const token1Address = getTokenAddress(outputCurrencyId).toLowerCase()

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const [onPresentImportTokenWarningModal] = useModal(
    <ImportTokenWarningModal tokens={importTokensNotInDefault} onCancel={() => router.push('/swap')} />,
  )

  useEffect(() => {
    if (importTokensNotInDefault.length > 0) {
      onPresentImportTokenWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTokensNotInDefault.length])

  const [onPresentConfirmModal] = useModal(
    <ConfirmSwapModal
      trade={trade}
      originalTrade={tradeToConfirm}
      onAcceptChanges={handleAcceptChanges}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      allowedSlippage={totalTax}
      onConfirm={handleSwap}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
    />,
    true,
    true,
    'confirmSwapModal',
  )

  const hasAmount = Boolean(parsedAmount)

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber()
    }
  }, [hasAmount, refreshBlockNumber])

  const usdtCurrency = useCurrency('0x55d398326f99059fF775485246999027B3197955')

  const { v2Trade: usdtInputTrade } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    usdtCurrency,
    recipient,
    undefined,
    undefined,
  )
  const inputTokenAmountUSD = usdtInputTrade?.outputAmount
    ? Math.round(parseFloat(usdtInputTrade?.outputAmount.toExact()) * 100) / 100
    : null

  const { v2Trade: usdtOutputTrade } = useDerivedSwapInfo(
    independentField,
    trade?.outputAmount.toExact(),
    outputCurrency,
    usdtCurrency,
    recipient,
    undefined,
    undefined,
  )

  const outputTokenAmountUSD = usdtOutputTrade?.outputAmount
    ? Math.round(parseFloat(usdtOutputTrade?.outputAmount.toExact()) * 100) / 100
    : null

  return (
    <>
      <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
        <Flex width="100%" justifyContent="center" position="relative">
          {!isMobile && (
            <PriceChartContainer
              inputCurrencyId={inputCurrencyId}
              inputCurrency={currencies[Field.INPUT]}
              outputCurrencyId={outputCurrencyId}
              outputCurrency={currencies[Field.OUTPUT]}
              isChartExpanded={isChartExpanded}
              setIsChartExpanded={setIsChartExpanded}
              isChartDisplayed={false}
              currentSwapPrice={singleTokenPrice}
            />
          )}
          <BottomDrawer
            content={
              <PriceChartContainer
                inputCurrencyId={inputCurrencyId}
                inputCurrency={currencies[Field.INPUT]}
                outputCurrencyId={outputCurrencyId}
                outputCurrency={currencies[Field.OUTPUT]}
                isChartExpanded={isChartExpanded}
                setIsChartExpanded={setIsChartExpanded}
                isChartDisplayed={isChartDisplayed}
                currentSwapPrice={singleTokenPrice}
                isMobile
              />
            }
            isOpen={isChartDisplayed}
            setIsOpen={setIsChartDisplayed}
          />
          <Flex flexDirection="column">
            <StyledSwapContainer $isChartExpanded={isChartExpanded}>
              <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
                <StyledAppBody>
                  <CurrencyInputHeader
                    title={t('Trade')}
                    subtitle={null}
                    setIsChartDisplayed={setIsChartDisplayed}
                    isChartDisplayed={isChartDisplayed}
                    hasAmount={hasAmount}
                    onRefreshPrice={onRefreshPrice}
                  />
                  <Wrapper id="swap-page">
                    <StyledHr />
                    <AutoColumn>
                      <CurrencyInputPanel
                        label={
                          independentField === Field.OUTPUT && !showWrap && trade ? t('From (estimated)') : t('From')
                        }
                        value={formattedAmounts[Field.INPUT]}
                        usdAmount={inputTokenAmountUSD}
                        showMaxButton={!atMaxAmountInput}
                        currency={currencies[Field.INPUT]}
                        onUserInput={handleTypeInput}
                        onMax={handleMaxInput}
                        onCurrencySelect={handleInputSelect}
                        otherCurrency={currencies[Field.OUTPUT]}
                        id="swap-currency-input"
                        showCommonBases
                      />

                      <AutoColumn justify="space-between">
                        <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0' }}>
                          <Flex width="100%" alignItems="center" justifyContent="space-between" mb="10px">
                            <Flex width="100%">
                              <SwitchHr />
                              <SwitchIconButton
                                variant="light"
                                scale="sm"
                                style={{ backgroundColor: 'transparent' }}
                                onClick={() => {
                                  setApprovalSubmitted(false) // reset 2 step UI for approvals
                                  onSwitchTokens()
                                }}
                              >
                                <ArrowUpDownIcon
                                  className="icon-up-down"
                                  color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? 'primary' : 'text'}
                                />
                              </SwitchIconButton>
                              <SwitchHr right />
                            </Flex>
                          </Flex>
                        </AutoRow>
                      </AutoColumn>
                      {recipient === null && !showWrap && isExpertMode ? (
                        <Button
                          style={{ marginBottom: 10 }}
                          variant="text"
                          id="add-recipient-button"
                          onClick={() => onChangeRecipient('')}
                        >
                          {t('+ Add a send (optional)')}
                        </Button>
                      ) : null}
                      <CurrencyInputPanel
                        value={formattedAmounts[Field.OUTPUT]}
                        usdAmount={outputTokenAmountUSD}
                        onUserInput={handleTypeOutput}
                        label={independentField === Field.INPUT && !showWrap && trade ? t('To (estimated)') : t('To')}
                        showMaxButton={false}
                        currency={currencies[Field.OUTPUT]}
                        onCurrencySelect={handleOutputSelect}
                        otherCurrency={currencies[Field.INPUT]}
                        id="swap-currency-output"
                        showCommonBases
                      />

                      {isExpertMode && recipient !== null && !showWrap ? (
                        <>
                          <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                            <ArrowWrapper clickable={false}>
                              <ArrowDownIcon width="16px" />
                            </ArrowWrapper>
                            <Button variant="text" id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                              {t('- Remove send')}
                            </Button>
                          </AutoRow>
                          <AddressInputPanel
                            style={{ marginBottom: 10 }}
                            id="recipient"
                            value={recipient}
                            onChange={onChangeRecipient}
                          />
                        </>
                      ) : null}
                    </AutoColumn>
                    <Box mt="0.25rem">
                      {swapIsUnsupported ? (
                        <Button width="100%" disabled>
                          {t('Unsupported Asset')}
                        </Button>
                      ) : !account ? (
                        <ConnectWalletButton width="100%" />
                      ) : showWrap ? (
                        <Button width="100%" disabled={Boolean(wrapInputError)} onClick={onWrap}>
                          {wrapInputError ??
                            (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                        </Button>
                      ) : noRoute && userHasSpecifiedInputOutput ? (
                        <GreyCard style={{ textAlign: 'center', padding: '0.75rem' }}>
                          <Text color="textSubtle">{t('Insufficient liquidity for this trade.')}</Text>
                          {singleHopOnly && <Text color="textSubtle">{t('Try enabling multi-hop trades.')}</Text>}
                        </GreyCard>
                      ) : showApproveFlow ? (
                        <RowBetween gap="10px">
                          <Button
                            variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                            onClick={approveCallback}
                            style={{ borderRadius: '16px' }}
                            disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                            width="50%"
                            height="70px"
                          >
                            {approval === ApprovalState.PENDING ? (
                              <AutoRow gap="6px" justify="center">
                                {t('Enabling')} <CircleLoader stroke="white" />
                              </AutoRow>
                            ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                              t('Enabled')
                            ) : (
                              t('Enable %asset%', { asset: currencies[Field.INPUT]?.symbol ?? '' })
                            )}
                          </Button>
                          <Button
                            variant={isValid && priceImpactSeverity > 2 ? 'danger' : 'primary'}
                            style={{ borderRadius: '16px' }}
                            onClick={() => {
                              if (isExpertMode) {
                                handleSwap()
                              } else {
                                setSwapState({
                                  tradeToConfirm: trade,
                                  attemptingTxn: false,
                                  swapErrorMessage: undefined,
                                  txHash: undefined,
                                })
                                onPresentConfirmModal()
                              }
                            }}
                            width="48%"
                            height="70px"
                            id="swap-button"
                            disabled={
                              !isValid ||
                              approval !== ApprovalState.APPROVED ||
                              (priceImpactSeverity > 3 && !isExpertMode)
                            }
                          >
                            {priceImpactSeverity > 3 && !isExpertMode
                              ? t('Price Impact High')
                              : priceImpactSeverity > 2
                              ? t('Swap Anyway')
                              : t('Swap Now')}
                          </Button>
                        </RowBetween>
                      ) : (
                        <Button
                          variant={isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'danger' : 'primary'}
                          style={{ borderRadius: '16px' }}
                          onClick={() => {
                            if (isExpertMode) {
                              handleSwap()
                            } else {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                txHash: undefined,
                              })
                              onPresentConfirmModal()
                            }
                          }}
                          id="swap-button"
                          width="100%"
                          height="70px"
                          marginBottom="18px"
                          marginTop="18px"
                          disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                        >
                          {swapInputError ||
                            (priceImpactSeverity > 3 && !isExpertMode
                              ? t('Price Impact Too High')
                              : priceImpactSeverity > 2
                              ? t('Swap Anyway')
                              : t('Swap Now'))}
                        </Button>
                      )}
                      {showApproveFlow && (
                        <Column style={{ marginTop: '1rem' }}>
                          <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                        </Column>
                      )}
                      {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                    </Box>
                    {/* <Flex justifyContent="right" mb="24px" mt="24px" mr="6px">
                      <Text fontSize="12px" mr="6px">
                        {t('AutoTax')}
                      </Text>
                      <Toggle
                        id="toggle-disable-multihop-button"
                        checked={autoTax}
                        scale="md"
                        onChange={() => {
                          setAutoTax(!autoTax)
                        }}
                      />
                      <Text fontSize="12px" ml="12px">
                        {t('Slippage')}
                      </Text>
                      <Input
                        id="toggle-disable-multihop-button"
                        value={maskSlippage}
                        style={{ width: '58px', height: '24px', marginLeft: '10px', marginTop: '-2px' }}
                        scale="md"
                        onChange={(e) => {
                          setMaskSlippage(e.target.value)
                        }}
                      />{' '}
                      <span style={{ paddingTop: 2, marginLeft: '2px' }}>%</span>
                    </Flex> */}
                    {!swapIsUnsupported ? (
                      trade && (
                        <>
                          {/* <hr
                            style={{
                              borderTop: '1px solid #D5D5D5',
                              borderBottom: '1px solid white',
                              margin: '0 0 20px',
                            }}
                          /> */}
                          {/* <AutoColumn gap="7px" style={{ marginTop: '20px' }}>
                            <RowBetween align="center">
                              <Text fontSize="12px" color="textSubtle">
                                {t('Slippage')}:
                              </Text>
                              <Text fontSize="12px" bold>
                                {allowedSlippage / 100}%
                              </Text>
                            </RowBetween>
                          </AutoColumn> */}
                          <TradeSummary
                            trade={trade}
                            allowedSlippage={totalTax}
                            autoTax={autoTax}
                            maskSlippage={maskSlippage}
                          />
                        </>
                      )
                    ) : (
                      <UnsupportedCurrencyFooter currencies={[currencies.INPUT, currencies.OUTPUT]} />
                    )}
                  </Wrapper>
                </StyledAppBody>
              </StyledInputCurrencyWrapper>
            </StyledSwapContainer>
            {isChartExpanded && (
              <Box display={['none', null, null, 'block']} width="100%" height="100%">
                <Footer variant="side" helpUrl={EXCHANGE_DOCS_URLS} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Page>
    </>
  )
}
